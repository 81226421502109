
export default {
  name: 'LoginPage',
  layout: 'fullscreen',
  auth: false,
  asyncData({ store, redirect, app }) {
    if (store.state.auth.user?.is_essay_user) {
      redirect('https://essay.hesse.ai')
      return
    }
    if (store.state.auth.loggedIn) {
      redirect(app.localePath('/feature/write-chapter'))
    }
  },
  data: () => ({
    durationWarning: false,
    stopLoading: false,
  }),
  mounted() {
    setTimeout(() => {
      this.durationWarning = true
    }, 12000)
    setTimeout(() => {
      this.stopLoading = true
    }, 30000)
  },
}
